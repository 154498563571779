import React from "react"

import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
    <Layout>
        <SEO title="Contact" keywords=""/>
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1>Contact</h1>
                </div>
                <p>Hi, I'm <span style={{fontWeight: 'bold'}}>Simone Montalto</span> and I'm a Software Engineer and Indie Developer. I started making iOS apps in September of 2016 and I've released my first app <Link to="https://booktrack.app" title={"Book Tracker" } target="_blank" rel="noopener noreferrer">Book Tracker</Link> as a solo developer in December 2019. I released my second app <Link to="https://movietrack.app" title={"Movie Tracker" } target="_blank" rel="noopener noreferrer">Movie Tracker</Link> in November 2022.</p>
                <p>At the moment I have released 5 apps in the App Store. In addition to the two mentioned before, you can also find <Link to="https://countdown-widget.app" title={"Countdown Widget" } target="_blank" rel="noopener noreferrer">Countdown Widget</Link>, <Link to="https://musictracker.app" title={"Music Tracker" } target="_blank" rel="noopener noreferrer">Music Tracker</Link> and <Link to="https://habittrack.app" title={"Habit Tracker" } target="_blank" rel="noopener noreferrer">Habit Tracker</Link>.</p>
                <p>You can follow me on X as <a href={"https://x.com/SimoneMontalto"} target={"_blank"} rel={"noreferrer"} title={"Simone Montalto X"}>@SimoneMontalto</a> and on Mastodon as <a href={"http://mastodon.social/@simonemontalto"} target={"_blank"} rel={"noreferrer"} title={"Simone Montalto Mastodon"}>@simonemontalto</a>. If you need technical support, if you want to send me feedbacks or reports or just want to say hello, you can send an email to <a href={"mailto:me@simonemontalto.com"} target={"_blank"} rel={"noreferrer"} title={"Simone Montalto Email"}>me@simonemontalto.com</a>.</p>
            </div>
        </div>
    </Layout>
)

export default ContactPage
